import { FormGroup } from '@angular/forms';
import packageJson from '../../../../package.json';

export const helpTitle = `Devakived HRMS`;

export const helpMessage = 'Something went wrong! Please contact support';
export const notFoundMessage = 'Requested Api not available!';

export function getApplicationVersion() {
  return packageJson.version;
}

export function getApplicationName() {
  return packageJson.name;
}

export const rowsPerPage = [25, 50, 100];

/**
 * Description placeholder
 *
 * @deprecated don't use in production. always remove post debugging
 */
export function findInvalidInForm(f: FormGroup) {
  const invalid: string[] = [];
  const controls = f.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  console.table(invalid);
  return invalid;
}

export function decodeText(inputString: string) {
  if (inputString?.length > 0) {
    const decodedText = decodeURIComponent(inputString);
    const txt = document.createElement("textarea");
    txt.innerHTML = decodedText;
    return txt.value;
  }
  return "";
}

export interface MenuChangeEvent {
  key: string;
  routeEvent?: boolean;
}

export interface LayoutMenuItem {
  label: string;
  icon?: string;
  routerLink?: string[];
  items?: LayoutMenuItem[];
  separator?: boolean;
  disabled?: boolean;
  visible?: boolean;
  url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  class?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fragment?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  command?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skipLocationChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParamsHandling?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replaceUrl?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preserveFragment?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routerLinkActiveOptions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  badgeClass?: any;
}

export enum StatusEnum {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}

export interface CountryType {
  name: string;
  code: string;
}

export interface Column {
  field: string;
  header: string;
  customExportHeader?: string;
  sortable?: boolean;
}

export interface ExportColumn {
  title: string;
  dataKey: string;
}

export interface RequestType {
  first: number;
  rows: number;
}

export interface StatusType {
  status: StatusEnum;
}

export const requestTypeData: RequestType = { first: 0, rows: 25 };
